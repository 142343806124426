<template>
	 <div class="current-box">
		<div class="current">
			<div class="title">하이프로 프로젝트 참여 현황</div>
			<div class="count_list"><p>◆ 참여건수</p><span class="count">{{pronPrjInfo.totCnt}}</span><span class="percent"></span></div>
			<div class="count_list"><p>◆ 완료건수</p><span class="count">{{pronPrjInfo.endCnt}}</span><span class="percent">{{pronPrjInfo.endPer}}</span></div>
			<div class="count_list"><p>◆ 이탈건수</p><span class="count leave">{{pronPrjInfo.midWithdrawalCnt}}</span><span class="percent leave">{{pronPrjInfo.midWithdrawalPer}}</span></div>
		</div>
		<div class="cp_count">
			<div class="title">이력서 참여 기간별 프로젝트 완료건수</div>
			<div class="standard"><p>참여기간 (완료기준)</p><p>단위 : 개월이상 / 건</p></div>
			<div class="month-box">
				<div class="month">0</div>	
				<div class="month">3</div>
				<div class="month">6</div>
				<div class="month">9</div>
				<div class="month">12</div>
				<div class="month">24</div>
			</div>
			<div class="count-box">
				<div class="count">{{resPrjInfo.zreMth}}건</div>
				<div class="count">{{resPrjInfo.trdMth}}건</div>
				<div class="count">{{resPrjInfo.sthMth}}건</div>
				<div class="count">{{resPrjInfo.nthMth}}건</div>
				<div class="count">{{resPrjInfo.twvMth}}건</div>
				<div class="count">{{resPrjInfo.tthMth}}건</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props:{
		tecMberSeq : {}
	},
	data() {
		return {
			techMberSeq : this.tecMberSeq || '',

			pronPrjInfo : {},
			resPrjInfo : {}
		}
	},

	mounted() {
		if(this.techMberSeq != '') this.getTecPrjBrif();
	},

	methods : {
		getTecPrjBrif() {
			this.$.httpPost('/api/mem/prf/getTecPrjBrif', {techMberSeq : this.techMberSeq})
				.then(res => {
					this.pronPrjInfo = res.data.pronPrjInfo;
					this.resPrjInfo = res.data.resPrjInfo;
				})
		}
	} 
}
</script>