<template>
	<div class="main-contents hr">
		<div class="tit">
			기술인재 최종 정보
			<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="goList()"/>
			<img src="/images/admin/special_rank_btn.png" alt="스페셜등급관리" @click="special()"/>
			<img src="/images/admin/certi_btn.png" alt="기술인증서발급" @click="printTecCert"/>
		</div>
		<div class="member-name"><span>{{data.mberNm}}</span> 회원님 
			<div class="tec-num">
				<img src="/images/tec/myp/logo_sm.png" alt="하이프로로고"/> 회원번호 <span class="num">{{data.tecSeq}}</span>
			</div>
		</div>

		<tecInfo v-model="data" type="admin" :tecMberSeq="tecMberSeq" ref="tecInfo" @click="clickTecInfo($event.type, $event.item)">
			<template v-slot:bottom>
				<div class="resume_detail_btn" v-if="!showResm">
					<img src="/images/admin/resume_detail_btn.png" alt="이력서 상세보기" @click="showResume()"/>
				</div>
			</template>
		</tecInfo>
		<project-brife :tecMberSeq="tecMberSeq"/>

		<div v-show="showResm">
			<!-- <div class="sub-tit" ref="resume"><hr><p>이력서 상세</p><hr></div> -->
			<div class="sub-tit" ref="resume"><p>이력서 상세</p></div>
			<tec-resume-view v-model="resume" :tecMberSeq="tecMberSeq" v-if="showResm || resume.regYn" @input="searchResm">
				<template v-slot:empty>
					<div class="dco_none">
						<p>등록하신 이력서가 없습니다!</p>
					</div>
				</template>
			</tec-resume-view>
		</div>
		<component v-show="!showResm" v-if="name != 'MEM912M02'" :key="name" :is="template" @searchEnd="searchEnd" ref="page" @move="move($event)"/>
		
		<!-- 기술회원 메모 -->
		<tec-memo :tecMberSeq="tecMberSeq"/>
	</div>
</template>

<script>
import tecInfo from "@/components/highpro/TecInfo.vue";
import tecMemo from "@/components/highpro/adm/TecMemo.vue";
import tecResumeView from "@/components/highpro/TecResumeView.vue";
import projectBrife from "@/components/highpro/TecInfo/TecInfoProjectBrife.vue";

export default {

	components : { tecInfo, tecResumeView, projectBrife, tecMemo },

	data() {
		return {
			tecMberSeq : this.$route.query.tecMberSeq,
			input:{},
			data: {},
			resume: { },
            resumeAttaFileDivCd: [],
            attaFileRelMap : {},
            attaFileRelEtcMap : {},
            attaFileRelEtcCount : 0,
			
			showResm: false,
			name: 'MEM912M02',
			histCnt  : 1
		};
	},
	watch: {
		$route(){
			//console.log('watch route', this.histCnt, this.$route.params.histCnt, this.$route);
			if(this.name != this.$route.name){
				//caller 경로가 이전 페이지 경로와 같으면 history +1 아니면 -1
				//if(this.name == (this.$route.params.caller||{}).name){
				//	this.histCnt ++;
				//} else {
				//	this.histCnt --;
				//}
				this.histCnt = this.$route.params.histCnt || 1;
				this.name = this.$route.name;
				
				if(this.$route.name == 'MEM912M02'){
					this.showResm = true;
				} else {
					this.showResm = false;
				}
			}
		}
	},
	beforeMount(){
		//console.log('watch route beforeMount', this.histCnt, this.$route.params.histCnt, this.$route);
		this.histCnt = this.$route.params.histCnt || 1;
		if(this.$route.name == 'MEM912M02'){
			this.showResm = true;
		} else {
			this.name = this.$route.name;
		}
	},
	methods: {
		goList(){
			//console.log('goList', this.histCnt, this.$route);
			this.$router.go(- this.histCnt)
		},
		move(info){
			var histCnt = this.histCnt + 1;
			if(typeof info == 'object'){
				info = info || {};
				info.params = info.params || {};
				info.params.histCnt = histCnt;
			} else if(typeof info == 'string'){
				info = { name: info, params: { histCnt }};
			}
			if(info.params.caller && info.params.caller.params){
				info.params.caller.params.histCnt = this.histCnt;
			}
			this.$router.push(info)
		},
        clickTecInfo(type, item){
			var tecMberSeq = this.tecMberSeq;
			var query = {tecMberSeq};
			var name = '';
			switch(type){
				case 'RESMVIEW'		: name = 'MEM912M03'; break;
				case 'INTREST'		: name = 'MEM912M04'; break;
				case 'POINT'		: name = 'MEM912M06'; break;
				case 'CLIENT_EVAL'	: name = 'MEM912M07'; break;
				case 'PRON_EVAL'	: name = 'MEM912M08'; break;
				case 'PROJ_NOW'		: name = 'PRJ901M01'; break;
				case 'PROJ_BEF'		: name = 'PRJ903M01'; break;
				case 'INTERVIEW'	: name = 'PRJ904M01'; break;
				case 'NCS'			: 
					name = 'MEM912M05';
					query.detailClassCd = item.detailClassCd;
					break;
			}

			if(name){
				this.showResm = false;
				this.input = query;
				var params = {
					histCnt : ++this.histCnt
				}

				var check = this.$route.name != name && Object.keys(query).length == Object.keys(this.$route.query).length;
				if(check == false){
					if(this.$route.name == 'MEM912M05' && this.$route.name != name) {
						check = true;
					}

					for(var key in query){
						if(query[key] != this.$route.query[key]){
							check = true;
							break;
						}
					}
				}

				if(check){
					this.$router.push({name, query, params})
				} else {
					this.searchEnd();
				}
			}
        },
		showResume(){
			this.showResm = true;
			if(this.resume.regYn){
				this.searchResm();
			}
		},
		searchResm(){
			if(this.name != 'MEM912M02'){
				this.$nextTick(() => {
					//console.log('searchResm', this.$refs.resume);
					window.scroll({top:this.$refs.resume.offsetTop, behavior: 'smooth'});
				});
			}
		},
		searchEnd(){//res
			this.$nextTick(() => {
				// console.log('searchEnd', this.$refs.page);
				window.scroll({top:this.$refs.page.$el.offsetTop, behavior: 'smooth'});
			});
		},
		special(){
			var tecMberSeq = this.tecMberSeq; 
			this.$.popup('/adm/mem/MEM912P01', { tecMberSeq })
				.then(res=> {
					if(res) this.$refs.tecInfo.search();
				});
		},
		printTecCert(){
			this.$.httpPost('/api/mem/adm/tec/setPrintTechCerti', {tecMberSeq : this.$route.query.tecMberSeq})
				.then(res => {
					//console.log('printTecCert RESULT', res);
					var url = "/REPORT?fileName=MEM910R02"
							+ "&issYyyy=" + res.data.issYyyy
							+ "&issSeq=" + res.data.issSeq
							+ "&verifyVal=" + res.data.verifyVal;

					window.open(url,"report","width=900,height=800, scrollbars=yes, resizable=yes");
				}).catch(this.$.httpErrorCommon);
		}

	},
	computed: {
		template() {
			if(!this.name || this.name == 'MEM912M02'){
				return 'div';
			} else {
				var path = this.name.substring(0, 3).toLowerCase();
				//console.log('template', path, this.name);
				return () => {
					return import('@/views/adm/' + path + '/' + this.name + '.vue')
						.catch(() => { 
							// console.log('PopupWrap', 'path', this.path);
							//console.error('PopupWrap', 'import path error', err);
						})
				};
			}
		},
	}
};
</script>
