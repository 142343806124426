<template>
	<div class="memo-box" :style="{ right: right + 'px' }">
		<img class="btn" :src="isOpen ? '/images/memo_btn_close.png' : '/images/memo_btn_open.png'" :alt="isOpen ? '메모닫기' : '메모열기'" @click="startAnimate">
		<div class="myp_box">
			<span class="name">{{info.mberNm}}</span><span class="age">({{info.gender | gender}}/{{info.birthYyyymmdd | birthYear}})</span><span class="cmp">{{info.corpNm}} / <SelectComp type="text" :value="info.empDivCd" cdId="PRO102"/></span>
			<div class="mem_num"><img src="/images/tec/myp/logo_sm.png" alt="하이프로로고"> 회원번호 <span class="num">{{tecMberSeq}}</span></div>
		</div>
		<div class="ct">
			<div class="profile">
				<div class="label myp">아이디</div><div class="value">{{info.loginId}}</div>
			</div>
			<div class="profile">
				<div class="label myp">휴대폰번호</div><div class="value">{{info.hpNo | phone}}</div>
			</div>
			<div class="profile">
				<div class="label myp">이메일</div><div class="value">{{info.email}}</div>
			</div>
			<div class="profile">
				<div class="label myp">생년월일</div><div class="value">{{info.birthYyyymmdd | date('yyyy년 MM월 dd일')}}</div>
			</div>
		</div>
		<div class="memo">
			<div class="memo_plus">
				<img src="/images/memo_plus_btn.png" alt="메모추가" @click="list.unshift({edit: {}})">
			</div>
			<!-- 메모리스트 -->
			<!-- 메모추가 안할 시 no_input 클래스 적용 , 메모추가 시 no_input 클래스 삭제-->
			<scroll class="memo_list no_input" @scrollBottom="handleScroll">
				<template v-for="(item, idx) in list">
					<!-- 메모추가 클릭 시 입력란 추가 -->
					<div class="input_plus" :key="getKey(item, idx)" v-if="isEdit(item)">
						<div class="icon_select">
							<SelectComp type="radio" v-model="item.edit.memoStatusCd" title="메모 상태" cdId="PRO127" v-slot="slot" rules="required" :showError="false">
								<span class="rd"></span><img :src="memoStatusImg((slot.item||{}).cd)" :alt="(slot.item||{}).cdNm"/>
							</SelectComp>
							<div class="save_btn">
								<img src="/images/memo_save.png" alt="저장버튼" @click="saveMemo(item, idx)"/>
								<img src="/images/memo_cancel.png" alt="취소버튼" @click="item.memoSeq ? $set(item, 'edit', false) : deleteMemo(item, idx)"/>
							</div>
						</div>
						<div class="memo_layout">
							<div class="left">
								<div class="date">{{item.regDate | date('yyyy.MM.dd')}}</div>
								<div class="writer">작성자 / {{item.regMberNm || $store.state.userInfo.mberNm}}</div>
							</div>
							<div class="right">
								<div class="title">
									<SelectComp type="text" v-model="item.edit.memoStatusCd" cdId="PRO127" v-slot="slot" v-if="item.edit.memoStatusCd && !item.edit.memoStatusCd.message">
										<img :src="memoStatusImg(slot.item.cd)" :alt="slot.item.cdNm"/>
									</SelectComp>
									<img :src="memoStatusImg(item.edit.memoStatusCd)" alt="아이콘" v-else/><nbsp/>
									<InputComp v-model="item.edit.memoTitle" title="제목" placeholder="제목을 입력해주세요" rules="required" :showError="false"/>
								</div>
								<div class="contents">
									<InputComp type="textarea" v-model="item.edit.memoCont" title="내용" placeholder="내용을 입력해주세요" :showError="false"/>
								</div>
							</div>
						</div>
					</div>
					<div class="memo_layout writen" v-else :key="getKey(item, idx)">
						<div class="modify_btn">
							<img src="/images/memo_delete.png" alt="삭제버튼" @click="deleteMemo(item, idx)">
							<img src="/images/memo_correct.png" alt="수정버튼" @click="$set(item, 'edit', Object.assign({}, item))">
						</div>
						<div class="left">
							<div class="date">{{ item.regDate | date('yyyy.MM.dd') }}</div>
							<div class="writer">작성자 / {{ item.regMberNm }}</div>
						</div>
						<div class="right">
							<div class="title">
								<SelectComp type="text" v-model="item.memoStatusCd" cdId="PRO127" v-slot="slot">
									<img :src="memoStatusImg(slot.item.cd)" :alt="slot.item.cdNm"/>
								</SelectComp>
								{{item.memoTitle}}
								<!--input type="text" placeholder="제목을 입력해주세요" value="'국민은행 클라우드 프로젝트' 중도이탈" readonly=""-->
							</div>
							<nbsp tagName="div" class="contents" :value="item.memoCont"/>
							<!--textarea placeholder="내용을 입력해주세요" readonly="">프로젝트 참여중 프로젝트 담당자와의 트러블 발생적립포인트 500,000 차감</textarea-->
						</div>
					</div>
				</template>
			</scroll>
		</div>
	</div>
</template>

<script>
import scroll from "@/components/Scroll.vue";
export default {
	components: { scroll },
	props: { tecMberSeq : {} },
	data() {
		return { 
			input:{
				tecMberSeq : this.tecMberSeq,
				nextKey : null,
			},
			data:{ },
			request: false,

			right: -1000,
			isOpen: false,
			dif : 20,
			closeSize : 10,
			timer: null,
		};
	},
	mounted(){
		this.right = this.getRight(false);
		//this.search();
	},
	destroyed(){
		this.stopAnimate();
	},
	methods : {
		search(){
			if(this.request) return;
			this.request = true;
			this.$.httpPost('/api/mem/adm/tec/getMemo', this.input, null, false)
				.then(res => {
					//console.log('search', res);
					this.request = false;
					if(res.data.mberSeq){
						this.data = res.data;
					} else if(Array.isArray(res.data.list)){
						if(!Array.isArray(this.data.list))
							this.data.list = [];
						this.data.list = this.data.list.concat(res.data.list);
					}
					this.input.nextKey = res.data.nextKey;
				});

		},
		getRight(isOepn){
			switch(isOepn){
				case true  : return 0;
				case false : return this.closeSize - this.$el.clientWidth;
				default    : return this.right;
			}
		},
		startAnimate(){
			this.stopAnimate();
			this.isOpen = !this.isOpen;
			if(this.isOpen){
				this.input.nextKey = null;
				this.search();
			}
			this.timer = setInterval(()=>{
				var to = this.getRight(this.isOpen);
				this.right += (this.isOpen ? 1 : -1) * this.dif;

				if(this.isOpen && this.right > to) this.right = to;
				else if(!this.isOpen && this.right < to) this.right = to;

				if(this.right == to){
					this.stopAnimate();
				}
			}, 10);
		},
		stopAnimate(){
			if(this.timer != null) clearInterval(this.timer);
		},
		
		isEdit(item){
			return typeof item.edit == 'object'
		},
		getKey(item, index){
			return item.memoSeq || ('Temp' + index);
		},
		memoStatusImg(memoStatusCd){
			switch(memoStatusCd){
				case 'A' : return '/images/memo_state_01.png';
				case 'B' : return '/images/memo_state_02.png';
				case 'C' : return '/images/memo_state_03.png';
				default  : return '/images/memo_state_00.png';
			}
		},

		saveMemo(item){
			//console.log('saveMemo', item, idx);
			if(!item.edit) return alert('저장할 정보가 없습니다.')
			var input = item.edit;
			input.tecMberSeq = this.tecMberSeq;
			this.$.httpPost('/api/mem/adm/tec/saveMemo', input)
				.then(res => {
					item.edit = false;
					item.memoSeq   = res.data.memoSeq;
					item.memoTitle = res.data.memoTitle;
					item.memoCont  = res.data.memoCont;
					item.memoStatusCd = res.data.memoStatusCd;
					item.regMberSeq = item.regMberSeq || this.$store.state.userInfo.mberSeq;
					item.regMberNm  = item.regMberNm  || this.$store.state.userInfo.mberNm;
					item.regDate    = item.regDate    || new Date().format('yyyyMMdd');
				});
		},
		deleteMemo(item, idx){
			//console.log('deleteMemo', item, idx);
			if(item.memoSeq){
				var memoSeq    = item.memoSeq;
				var tecMberSeq = this.tecMberSeq;
				this.$.httpPost('/api/mem/adm/tec/deleteMemo', {memoSeq, tecMberSeq})
					.then(() => {
						for(var i in this.list){
							if(this.list[i].memoSeq == memoSeq){
								this.list.splice(i, 1);
							}
						}
					});
			} else {
				this.list.splice(idx, 1);
			}
		},

		handleScroll(){
			//console.log('handleScroll', event)
			this.search();
		}
	},
	computed:{
		info(){
			if(this.data.mberSeq) return this.data;
			else return {};
		},
		list(){
			if(Array.isArray(this.data.list)) return this.data.list;
			else return [];
		}
	}
}
</script>