<template>
	<div  v-if="resume.regYn != 'N'">
		<slot name="empty">
			<div class="contents-body pf">
				<div class="about">
					<div class="box">
						<img class="career_caution" src="/images/tec/myp/career_caution.png" alt="이력서미등록"/>
						<p @click="$router.push('PRF101M01')">이력서 등록하러 가기 &#9654;</p>
					</div>
				</div>
			</div>
		</slot>
	</div>
	<div v-else>
		<!-- 자기소개 -->
		<div class="contents-body pf">
			<div class="tit">자기소개</div>
			<nbsp tagName="div" class="about" :value="resume.selfIntroCont" v-if="resume.selfIntroCont"/>
			<div class="about empty" v-else>등록 된 자기소개가 없습니다.</div>
		</div>
		<!-- 보유기술 -->
		<div class="contents-body career">
			<div class="outline">
				<div class="box">보 유 기 술</div>
				<div class="box_contents" v-if="resume.techLevelList && resume.techLevelList.length > 0">
					<div class="list" v-for="(item, idx) in resume.techLevelList" :key="idx">
						<div class="tech">{{item.techNm}}</div> / 
						<div class="level"><SelectComp type="text" :value="item.techLevelCd" cdId="PRO125"/></div>
					</div>
				</div>
				<div class="box_contents empty" v-else>등록 된 보유기술이 없습니다.</div>
			</div>
			<div class="outline">
				<div class="box">직 무 분 야</div>
				<div class="box_contents" v-if="resume.dutyCdList && resume.dutyCdList.length > 0">
					<!-- <div class="list"><SelectComp type="text_comma_split" :value="resume.dutyCdList" cdId="PRO119"/></div> -->
					<div class="list" v-for="(item, idx) in resume.dutyCdList" :key="idx">
						<div class="tech"><SelectComp type="text"  :value="item.cd.substr(0, 2)" cdId="PRO141"/></div> / 
						<div class="level"><SelectComp type="text" :value="item.cd" cdId="PRO142"/></div>
					</div>
				</div>
				<div class="box_contents empty" v-else>등록 된 직무분야가 없습니다.</div>
			</div>
			<div class="outline">
				<div class="box">전 문 분 야</div>
				<div class="box_contents" v-if="resume.expertCdList && resume.expertCdList.length > 0">
					<div class="list" v-for="(item, idx) in resume.expertCdList" :key="idx">
						<div class="tech" style="width: 400px;"><SelectComp type="text"  :value="item.cd.substr(0, 1)" cdId="PRO105"/></div> / 
						<div class="level"><SelectComp type="text" :value="item.cd" cdId="PRO143"/></div>
					</div>
				</div>
				<div class="box_contents empty" v-else>등록 된 전문분야 가 없습니다.</div>
			</div>
			<div class="outline">
				<div class="box">경 력</div>
				<div class="box_contents">
					<div class="list" v-if="resume.totWorkMonthCnt">{{resume.totWorkMonthCnt | months}}</div>
					<div class="list empty" v-else>등록 된 경력이 없습니다.</div>
				</div>
			</div>
			<div class="outline">
				<template v-if="resume.schoolList && resume.schoolList.length > 0">
					<template v-for="(item, idx) in resume.schoolList">
						<div :key="'box'+item.schoolSeq" class="box" :class="{fix: idx >0}">학 력</div>
						<div :key="'conts'+item.schoolSeq" class="box_contents certi">
							<!-- <div class="list edu">{{item.schoolNm}}</div> -->
							<div class="list edu">{{item.schoolNm}}<SelectComp style="float:right" cdId="PRO121" v-model="item.schoolDivCd" type="text"></SelectComp></div>
							<!-- <div class="list edu">{{item.schoolDivCd}}</div> -->
							<div class="list edu">{{item.majorNm}}</div>
							<div class="list edu">{{item.entYyyymm | date('yyyy년 MM월')}} ~ {{item.gradYyyymm | date('yyyy년 MM월')}}<SelectComp style="float:right" cdId="PRO122" v-model="item.schoolStatusCd" type="text"></SelectComp></div>
						</div>
					</template>
				</template>
				<template v-else>
					<div class="box">학 력</div>
					<div class="box_contents certi empty">등록 된 학력이 업습니다.</div>
				</template>
			</div>
			<div class="outline">
				<template v-if="resume.licenseList && resume.licenseList.length > 0">
					<template v-for="(item, idx) in resume.licenseList">
						<div :key="'box'+item.resumeLicenseSeq" class="box" :class="{fix: idx >0}">자 격 증</div>
						<div :key="'conts'+item.resumeLicenseSeq" class="box_contents certi">
							<div class="list edu">{{item.licenseNm}}</div>
							<div class="list edu">{{item.acqYyyymm | date('yyyy년 MM월')}}</div>
							<div class="list edu certi">{{item.issOrgNm}}</div>
						</div>
					</template>
				</template>
				<template v-else>
					<div class="box">자 격 증</div>
					<div class="box_contents certi empty">등록 된 자격증이 업습니다.</div>
				</template>
			</div>
		</div>
		<!-- 재직경력 -->
		<div class="frame">
			<div class="myp_tit">재직경력</div>
			<template v-if="resume.careerList && resume.careerList.length > 0">
				<div class="contents-body career" v-for="item in resume.careerList" :key="item.careerSeq">
					<div class="outline">
						<div class="box ivl02">회 사 명</div>
						<div class="box_contents">
							<div class="list">{{item.companyNm}}</div>
						</div>
					</div>
					<div class="outline">
						<div class="box ivl06">직급/직책</div>
						<div class="box_contents">
							<div class="list">{{item.positionNm}}</div>
						</div>
					</div>
					<div class="outline">
						<div class="box">근 무 형 태</div>
						<div class="box_contents">
							<div class="list"><SelectComp type="text" :value="item.workPatCd" cdId="PRO123"/></div>
						</div>
					</div>
					<div class="outline">
						<div class="box">담 당 업 무</div>
						<div class="box_contents">
							<div class="list">{{item.assigWorkNm}}</div>
						</div>
					</div>
					<div class="outline">
						<div class="box">근 무 기 간</div>
						<div class="box_contents">
							<div class="list">{{item.workStartYyyymm | date('yyyy년 MM월')}} ~ {{item.workEndYyyymm | date('yyyy년 MM월')}}</div>
						</div>
					</div>
				</div>
			</template>
			<div class="contents-body career empty" v-else>등록 된 재직경력이 없습니다.</div>
		</div>
		<!-- 프로젝트경력 -->
		<div class="frame">
			<div class="myp_tit">프로젝트경력</div>
			<template v-if="resume.projectList && resume.projectList.length > 0">
				<div class="contents-body career" v-for="item in resume.projectList" :key="item.resumeProjectSeq">
					<div class="both">
						<!-- 왼쪽 -->
						<div class="left">
							<div class="outline prj">
								<div class="box ivl01">프로젝트명</div>
								<div class="box_contents">
									<div class="list prj limit">{{item.projectNm}}</div>
								</div>
							</div>
							<div class="outline prj">
								<div class="box">고 객 사 명</div>
								<div class="box_contents">
									<div class="list prj">{{item.clientNm}}</div>
								</div>
							</div>
							<div class="outline prj">
								<div class="box">산 업 구 분</div>
								<div class="box_contents">
									<div class="list prj"><SelectComp type="text" :value="item.clientIndtypeClassCd" cdId="PRO105"/></div>
								</div>
							</div>
							<div class="outline prj">
								<div class="box">수 행 사 명</div>
								<div class="box_contents">
									<div class="box_contents">{{item.workCompanyNm}}</div>
								</div>
							</div>
							<div class="outline prj">
								<div class="box">근 무 형 태</div>
								<div class="box_contents">
									<div class="box_contents"><SelectComp type="text" :value="item.workPatCd" cdId="PRO123"/></div>
								</div>
							</div>
							<div class="outline prj">
								<div class="box">수 행 업 무</div>
								<div class="box_contents">
									<div class="list prj">{{item.exeWorkCont}}</div>
								</div>
							</div>
							<div class="outline prj">
								<div class="box">수 행 직 무</div>
								<div class="box_contents">
									<div class="box_contents">{{item.exeDutyCont}}</div>
								</div>
							</div>
							<div class="outline prj">
								<div class="box">참 여 구 분</div>
								<div class="box_contents">
									<div class="box_contents"><SelectComp type="text" :value="item.joinDivCd" cdId="PRO124"/></div>
								</div>
							</div>
						</div>
						<!-- 오른쪽 -->
						<div class="right">
							<div class="outline prj">
								<div class="box prj">개 발 기 간</div>
								<div class="box_contents">
									<div class="list devp">{{item.joinStartYyyymm | date('yyyy년 MM월')}} ~ {{item.joinEndYyyymm | date('yyyy년 MM월')}} [{{item.joinMonthCnt | months}}]</div>
								</div>
							</div>
							<div class="outline prj">
								<div class="box prj">개발환경기종</div>
								<div class="box_contents">
									<div class="list devp">{{item.devServer}}</div>
								</div>
							</div>
							<div class="outline prj">
								<div class="box prj">개발환경OS</div>
								<div class="box_contents">
									<div class="list devp">{{item.devOs}}</div>
								</div>
							</div>
							<div class="outline prj">
								<div class="box prj">개발환경언어</div>
								<div class="box_contents">
									<div class="list devp">{{item.devLang}}</div>
								</div>
							</div>
							<div class="outline prj">
								<div class="box prj">개발환경DBMS</div>
								<div class="box_contents">
									<div class="list devp">{{item.devDbms}}</div>
								</div>
							</div>
							<div class="outline prj">
								<div class="box prj">개발환경TOOL</div>
								<div class="box_contents">
									<div class="list devp">{{item.devTool}}</div>
								</div>
							</div>
							<div class="outline prj">
								<div class="box prj">개발환경통신</div>
								<div class="box_contents">
									<div class="list devp">{{item.devComm}}</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 상세설명 -->
					<div class="outline">
						<div class="box">상 세 설 명</div><br/><br/>
						<nbsp tagName="div" class="about" :value="item.projectDtlDesc"/>
					</div>
				</div>
			</template>
			<div class="contents-body career empty" v-else>등록 된 프로젝트경력이 없습니다.</div>
		</div>

		<!-- 증빙서류 -->
		<div class="frame">
			<div class="myp_tit">증빙서류</div>
			<div class="contents-body career">
				<template v-for="code in resumeAttaFileDivCd">
					<!-- 사진, 기타 제외-->
					<div class="outline docu" :key="code.cd" v-if="['01', '99'].indexOf(code.cd) < 0">
						<!-- 첨부 -->
						<div class="wrap" v-for="(item, idx) in attaFileRelMap[code.cd]" :key="code.cd + '_' + idx">
							<div class="box docu"><template v-if="idx == 0">{{code.cdNm}}</template></div>
							<div class="box_contents">
								<div class="list docu">
									<template v-if="idx == 0">
										첨부됨
										<span v-if="attaFileRelMap[code.cd].length > 1"> : {{attaFileRelMap[code.cd].length}}</span>
									</template>
								</div>
							</div>
							<div class="docu_download" @click="clickAttaFile(item)">
								<img src="/images/tec/myp/docu_download.png" alt="문서다운로드"/>
								<p>{{item.orgFileNm}}</p>
							</div>
						</div>
						<!-- 미첨부 -->
						<div class="wrap" v-if="!attaFileRelMap[code.cd]">
							<div class="box docu">{{code.cdNm}}</div>
							<div class="box_contents">
								<div class="list docu none">미첨부</div>
							</div>
							<div class="docu_download none">
								&lowbar; &lowbar; &lowbar;
							</div>
						</div>
					</div>
				</template>
				<!-- 기타 문서 : 첨부 -->
				<div class="outline docu"  v-for="(list, idx1) in attaFileRelEtcMap" :key="idx1">
					<div class="wrap" v-for="(item, idx2) in list" :key="idx1 + '_' + idx2">
						<div class="box docu"><template v-if="idx2 == 0">{{item.resumeAttaFileDataNm}}</template></div>
						<div class="box_contents">
							<div class="list docu">
								<template v-if="idx2 == 0">
									첨부됨
									<span v-if="list.length > 1"> : {{list.length}}</span>
								</template>
							</div>
						</div>
						<div class="docu_download" @click="clickAttaFile(item)">
							<img src="/images/tec/myp/docu_download.png" alt="문서다운로드"/>
							<p>{{item.orgFileNm}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 수상경력 -->
		<div class="frame">
			<div class="myp_tit">수상경력</div>
			<div class="contents-body career" v-if="resume.awardsCareerCont">
				<nbsp tagName="div" class="outline" lineTag="p" lineClass="awards" :value="resume.awardsCareerCont"/>
			</div>
			<div class="contents-body career empty" v-else>등록 된 수상경력이 없습니다.</div>
		</div>
		<!-- 희망근무조건 -->
		<div class="frame hope">
			<div class="myp_tit">희망근무조건</div>
			<div class="contents-body career">
				<div class="outline">
					<div class="box">사 업 형 태</div>
					<div class="box_contents" v-if="resume.hopeEmplPatCdList">
						<div class="list"><SelectComp type="text_comma_split" :value="resume.hopeEmplPatCdList" cdId="PRO112"/> </div>
					</div>
					<div class="box_contents empty" v-else>등록 된 희망 사업형태가 없습니다.</div>
				</div>
				<div class="outline">
					<div class="box">고 용 형 태</div>
					<div class="box_contents" v-if="resume.hopeBizPatCdList">
						<div class="list"><SelectComp type="text_comma_split" :value="resume.hopeBizPatCdList" cdId="PRO113"/> </div>
					</div>
					<div class="box_contents empty" v-else>등록 된 희망 고용형태가 없습니다.</div>
				</div>
				<div class="outline">
					<div class="box hope">추가 희망 근무조건</div><br/><br/>
					<nbsp tagName="div" class="about" :value="resume.hopeWorkCondEtcCont" v-if="resume.hopeWorkCondEtcCont" />
					<div class="about empty" v-else>등록 된 추가 희망 근무조건이 없습니다.</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  components: { },
	props: { tecMberSeq: {} },
	data(){
		return {
            resume: { },
            resumeAttaFileDivCd: [],
            attaFileRelMap : {},
            attaFileRelEtcMap : {},
            attaFileRelEtcCount : 0,
		 }
	},
	mounted(){
        this.$store.dispatch("commonCode").then((res) => {
            this.resumeAttaFileDivCd = res['PRO114'];
        });
        this.search();
	},
	methods: {
        search(){
			var tecMberSeq = this.tecMberSeq;
            this.$.httpPost('/api/mem/prf/getResume', {tecMberSeq})
                .then(res => {
                    this.resume = res.data;
					this.$emit('input', this.resume);

                    this.attaFileRelMap = {};
                    this.attaFileRelEtcMap = {};

                    for(var i in this.resume.attaFileRelList){
                        var item = this.resume.attaFileRelList[i];
                        if(item.resumeAttaFileDivCd == '99'){
                            if(!this.attaFileRelEtcMap[item.resumeAttaFileDataNm]){
                                this.attaFileRelEtcMap[item.resumeAttaFileDataNm] = [];
                            }
                            this.attaFileRelEtcMap[item.resumeAttaFileDataNm].push(item);
                        } else {
                            if(!this.attaFileRelMap[item.resumeAttaFileDivCd]){
                                this.attaFileRelMap[item.resumeAttaFileDivCd] = [];
                            }
                            this.attaFileRelMap[item.resumeAttaFileDivCd].push(item);
                        }
                    }
                });
        },
        clickAttaFile(item){
            //console.log('clickAttaFile', item)
            if(item.attaFileSeq){
				var tecMberSeq = this.tecMberSeq;
				var attaFileSeq = item.attaFileSeq;
                this.$.fileDownload('/api/mem/prf/downResumeFile', {tecMberSeq, attaFileSeq}, {}, 'POST', true)
					.catch(this.$.httpErrorCommon);
            }
        },
	}
}
</script>
<style scoped>
.empty { color: #A7A9AC !important; }
</style>